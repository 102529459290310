@import url('../../global.css');

.nav-link {
    text-decoration: none;
    color: #B1B0AF;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.navbar {
    box-shadow: 0 5px 10px -2px rgba(153, 152, 152, 0.5);
    background-color: #f8f5f5;
}

.icon-size {
    font-size: 1.8rem;
    color: #848484;
}

.notif {
    position: absolute;
    width: 10px;
    height: 15px;
    border-radius: 50%;
}

.active {
    color: #dc3545 !important;
}

.lang-select {
    background-color: transparent;
    border: none;
}

.logo {
    margin-right: 10px;
    width: 45px;
}