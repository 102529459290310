@import url('../../global.css');

.text-publi-1 {
    text-align: center;
}

.text-publi-2 {
    text-align: center;
    font-style: italic;
}

.btn-home {
    min-width: 80%;
    width: 80%;
}

.collection {
    background-color: #848484;
    border-color: #848484;
}

.collection:hover {
    background-color: #848484;
    border-color: #848484;
}

.create {
    border-color: #848484;
    color: #848484;
}

.link {
    cursor: pointer;
}

.home-cont {
    font-family: 'OpenSans', sans-serif;
    color: #848484;
}

.text {
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.title {
    font-weight: 600;
}

.slider-img {
    max-height: 550px;
    object-fit: contain;
}

.shop-img {
    max-height: 450px;
    cursor: pointer;
    object-fit: contain;
}

.bordes {
    font-family: 'OpenSans', sans-serif;
    font-weight: 600;
    color: #515151;
}

.price-shop {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 0.9rem;
    color: #999999;
}

.price-eth {
    font-family: 'OpenSans', sans-serif;
    font-weight: 600;
    margin-left: 5px;
    color: #515151;
}

.avatar-inter {
    border: 2px solid white;
    margin-right: -5px;
}

.info-shop {
    width: 65%;
}

.swiper {
    width: 100%;
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-slide:nth-child(1n) img {
    width: 90% !important;
    height: 90%;
}

.carousel-indicators{ 
    display: none !important; 
}

.text-title {
    height: 80px;
}