@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'OpenSans';
    src: url('./assets/fonts/OpenSans-Bold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Bold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Medium.ttf');
}

.bg-color {
    background-color: #f8f5f5;
}

.bg-footer {
    background-color: #848484;
}

.bg-footer-publi {
    background-color: #848484;
    height: 80px;
    width: 100%;
    opacity: 1;
    border-top: 1px solid #9a9696;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}

@media only screen and (max-width : 1100px){ /* You can edit the max-width value to match what you need */
  .bg-footer-publi {
    height: 100%;
    width: 100%;
    opacity: 1;
    border-top: 1px solid #9a9696;
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    margin-top:30px; /* You can edit the value of the margin top as you need */
  }
}

body {
    min-height: calc(100vh - 86px);
    box-sizing: border-box;
    background-color: #f8f5f5;
}

.link {
    cursor: pointer;
}

.labels {
    text-transform: uppercase;
}