@import url('../../global.css');

.avatar {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
}

.align-right {
    text-align: right;
}

.subtitle {
    color: #999999;
    font-family: 'Roboto';
    font-weight: 500;
}

.name {
    margin-left: 10px;
    color: #AE0303;
    font-family: 'Roboto';
    font-weight: 600;
}

.title {
    color: #848484;
    font-family: 'OpenSans', sans-serif;
    font-weight: 600;
}

.price {
    font-family: 'OpenSans', sans-serif;
    font-weight: 600;
    font-size: 38px;
    margin-left: 10px;
}

.btn-global {
    background-color: #848484;
    border-color: #848484;
    min-width: 100%;
    width: 100%;
}

.btn-global:hover {
    background-color: #848484;
    border-color: #848484;
}

.offer {
    border-color: #848484;
    color: #848484;
    min-width: 100%;
    width: 100%;
}

.cont-dolar {
    width: calc(100% - 620px);
}

.dolar {
    color: #999999;
    text-align: right;
}

.nft-name {
    color: #848484;
    font-family: 'OpenSans', sans-serif;
}

.subtitle-owner {
    color: #999999;
    font-family: 'Roboto';
    font-weight: 400;
}

.name-owner {
    font-family: 'Roboto';
    color: #515151;
    margin-left: 10px;
}


/* ACORDDION CLASS */
.accordion-header {
    background-color: #f8f5f5;
    font-family: 'Roboto';
}
.accordion-item {
    background-color: #f8f5f5;
}
.accordion-button {
    background-color: #f8f5f5;
    color: #515151 !important; 
}
.accordion-button:focus {
    border-color: #f8f5f5;
}
.accordion-button:not(.collapsed) {
    background-color: #f8f5f5;
    color: #515151 !important;
    box-shadow: none;
}
.eth {
    margin-right: 5px;
}
th {
    font-weight: 400;
    color: #848484;
    font-family: 'Roboto';
}
.table>:not(:first-child) {
    border-top: 1px solid currentColor;
}

.dropdown a {
    text-decoration: none;
    color: #848484;
    font-family: 'Roboto';
    border-bottom: 1px solid #848484;
}

.dropdown:hover {
    color: #848484;
}

.dropdown-item {
    border-bottom: 0 !important;
}

.link {
    cursor: pointer;
}

.accordion-button {
    padding-left: 0 !important;
}

.accordion-body {
    padding-left: 0 !important;
}

.contract {
    text-align: right;
    margin-left: 5px;
}

/* PLACEHOLDER */

.img-placeholder {
    height: 400px;
}

.header-placeholder {
    display: flex;
    justify-content: space-between;
}

.table-placeholder {
    height: 150px;
}

.chart-placeholder {
    height: 250px;
}